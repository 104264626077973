import React, { useEffect, useState } from "react";
import VendorSideBar from "../Components/VendorSideBar";
import axios from "axios";
import { API_URL } from "../../config";
import { useNavigate, useParams } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";
import { HiMiniArrowTrendingUp } from "react-icons/hi2";
import NavigationBar from "../Components/NavigationBar";

const YourEvents = () => {
    const { event_id } = useParams(); //event unique value
    const [eventDetail, setEventDetail] = useState([])
    const [users, SetUsers] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [isRevenue, setIsRevenue] = useState(false);
    const [isTicketSold, setIsTicketSold] = useState(false);
    const [isTicketHost, setIsTicketHost] = useState(false);
    const [ticket_hosted, setTicket_hosted] = useState(0);
    const [ticket_sold, setTicket_sold] = useState(0);
    const [Revenue, setRevenue] = useState(0);
    const navigate = useNavigate();


    const getEventDetails = async () => {
        try {
            const id = event_id;

            // Fetch Event Details
            const { data: eventData, status } = await axios.get(`${API_URL}/api/eventdetail/${id}`);
            if (status === 200 && eventData.length > 0) {
                setEventDetail(eventData[0]); // Update state with event details


                let total_ticket = 0;

                // Calculate ticket details
                for (const ticket of eventData[0].ticket) {
                    total_ticket += ticket.total_Quantity;
                }

                // Update ticket states
                setTicket_hosted(total_ticket);
            }

            // Fetch User Details

            const userResponse = await axios.get(`${API_URL}/pay/events/${eventData[0]._id}`);
            console.log("USER_RESPONSE :", userResponse.data)
            if (userResponse.status === 200 && userResponse.data.users) {
                const FilterPaid = userResponse.data.users.filter(paidTicket => paidTicket && paidTicket.booking.isPaid)
                SetUsers(FilterPaid);
                let ticket_sell = 0;
                let total_revenue = 0
                for (const ticket of userResponse.data.users) {
                    if (ticket.booking.isPaid) {
                        ticket_sell += ticket.booking.bookedTicketQuantities;
                        total_revenue += parseInt(ticket.booking.amount / 100);
                    }
                }
                if (ticket_sell) {
                    setTicket_sold(ticket_sell);
                }
                if (total_revenue) {
                    setRevenue(total_revenue);
                }


            } else {
                console.warn("No user data found!");
            }
        } catch (error) {
            console.error("Failed to fetch event details:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const getTicketSold = (ticket_id) => {
        const matchingUsers = users.filter(user => 
            user.booking && 
            user.booking.tickets && 
            user.booking.tickets.some(ticket => ticket._id === ticket_id)
        );

        const totalBooked = matchingUsers.reduce((total, user) => {
            const bookedQuantities = Array.isArray(user.booking.bookedTicketQuantities) 
                ? user.booking.bookedTicketQuantities.reduce((sum, qty) => sum + qty, 0)
                : user.booking.bookedTicketQuantities;
            return total + (bookedQuantities || 0);
        }, 0);
    
        return totalBooked;
    };
    
    
    
    const getTicketRevenue = (ticket_id) => {
        const matchingUsers = users.filter(user => 
            user.booking && 
            user.booking.tickets && 
            user.booking.tickets.some(ticket => ticket._id === ticket_id)
        );

        console.log(`Total Booked Tickets for ${ticket_id}:`, matchingUsers);
        const revenueAmt = matchingUsers.reduce((total, user) => {
            const revenueAmount = Array.isArray(user.booking.amount) 
                ? user.booking.amount.reduce((sum, qty) => sum + qty, 0)
                : user.booking.amount;
            return total + (revenueAmount || 0);
        }, 0);
    
        console.log(`Total Booked Tickets for ${ticket_id}:`, revenueAmt);
    
        return revenueAmt;
    }

    // Optional: Log users when updated
    useEffect(() => {
        // console.log("Updated users:", users);
    }, [users]);


    const handleEdit = (event_id) => {
        navigate(`/vendor/editevent-detail/${event_id}`)
    }

    useEffect(() => {
        getEventDetails();
    }, [event_id]);

    return (
        <>
            <NavigationBar />
            <div className="container-fluid">
                {isLoading ? (
                    <div className='d-flex justify-content-center align-items-center' style={{ height: "50vh" }}>
                        <PuffLoader size={48} loading={isLoading} />
                    </div>
                ) : (
                    <>
                        <div className="row">
                            <VendorSideBar />
                            <div className="col-lg-10 col-md-10 col-12">
                                <div>
                                    <>
                                        <div className='d-flex justify-content-between border-bottom border-primary'>
                                            <span className="vender-heading">{eventDetail.event_name}</span>
                                            <span><button className="edit-btn" onClick={e => handleEdit(event_id)}>Edit</button></span>
                                        </div>
                                        <div className="row mb-5 mt-3">
                                            <div className='col-lg-3 col-md-3'>
                                                <div className="card" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={(e) => { setIsRevenue(false); setIsTicketHost(true); setIsTicketSold(false) }}>
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <p className='dashboard-summary fw-semibold'>Total Tickets Hosted</p>
                                                                <p className="count-style fw-semibold">{ticket_hosted}</p>
                                                            </div>
                                                            <div></div>
                                                        </div>
                                                        <div>
                                                            <span className='dashboard-summary fw-semibold'><span className="text-success"><HiMiniArrowTrendingUp /> 1.8% </span>
                                                                <span>Up from year</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-md-3'>
                                                <div className="card" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={(e) => { setIsRevenue(false); setIsTicketHost(false); setIsTicketSold(true) }}>
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <p className='dashboard-summary fw-semibold'>Total Tickets Sold</p>
                                                                <p className="count-style fw-semibold">{ticket_sold ? ticket_sold : "0"}</p>
                                                            </div>
                                                            <div></div>
                                                        </div>
                                                        <div>
                                                            <span className='dashboard-summary fw-semibold'><span className="text-success"><HiMiniArrowTrendingUp /> 1.3% </span>
                                                                <span>Up from past week</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4'>
                                                <div className="card" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={(e) => { setIsRevenue(true); setIsTicketHost(false); setIsTicketSold(false) }}>
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <p className='dashboard-summary fw-semibold'>Total Revenue</p>
                                                                <p className="count-style fw-semibold">{Revenue ? (Revenue).toFixed(2) : "0"}</p>
                                                            </div>
                                                            <div></div>
                                                        </div>
                                                        <div>
                                                            <span className='dashboard-summary fw-semibold'><span className="text-success"><HiMiniArrowTrendingUp /> 4.3% </span>
                                                                <span>Down from yesterday</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <p className="vender-heading">Ticket Selling Details </p>
                                        <table className="table table-hover">
                                            <thead className="tableHead">
                                                <tr>
                                                    <th scope="col">Sr. No.</th>
                                                    <th className="hideit_768" scope="col">Customer Name</th>
                                                    <th className="hideit_768" scope="col">Date of Booking</th>
                                                    <th className="hideit_768" scope="col">Date of Event</th>
                                                    <th className="hideit_768" scope="col">No. of Tickets</th>
                                                    <th className="hideit_768" scope="col">Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users.length > 0 ? (
                                                    users.sort((a, b) => new Date(b.booking.createdAt) - new Date(a.booking.createdAt)).map((event, index) => {
                                                        return (
                                                            <tr className="tableDataRow" key={event.booking._id}>
                                                                <td>{index + 1}</td>
                                                                <td>{event.user.firstName}</td>
                                                                <td className="hideit_768">{new Date(event.booking.createdAt).toLocaleDateString('en-GB').replace(/\//g, '-')}</td>
                                                                <td className="hideit_768">{new Date(event.booking.eventreserveDate).toLocaleDateString('en-GB').replace(/\//g, '-')}</td>
                                                                <td className="hideit_768">{event.booking.bookedTicketQuantities}</td>
                                                                <td className="hideit_768">{parseInt(event.booking.amount / 100).toFixed(2)}</td>
                                                            </tr>
                                                        )
                                                    })
                                                ) : <p>.........No Event Added........</p>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
                }
            </div>

            {/* <!-- Modal --> */}
            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div className="p-1 text-end">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div>
                                <table class="table">
                                    <thead>
                                        <tr className="text-center">
                                            <th scope="col">Sr.No</th>
                                            <th scope="col">Ticket Name</th>
                                            <th scope="col">Ticket Date</th>
                                            <th className={`${!isTicketHost ? 'd-none' : 'd-block'}`} scope="col">Ticket Hosted</th>
                                            <th className={`${!isTicketSold ? 'd-none' : 'd-block'}`} scope="col">Ticket Sold</th>
                                            <th className={`${!isRevenue ? 'd-none' : 'd-block'}`} scope="col">Total Revenue</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                           eventDetail.ticket && eventDetail.ticket.length > 0 ?
                                                eventDetail.ticket.map((tick, index) => {
                                                    return (
                                                        <tr className="text-center" key={tick._id}>
                                                            <td scope="col">{index + 1}</td>
                                                            <td scope="col">{tick.ticket_Name}</td>
                                                            <td scope="col">{new Date(tick.ticket_for_Date).toLocaleDateString('en-GB').replace(/\//g, '-')}</td>
                                                            <td className={`${!isTicketHost ? 'd-none' : 'd-block'}`} scope="col">{tick.total_Quantity}</td>
                                                            <td className={`${!isTicketSold ? 'd-none' : 'd-block'}`} scope="col">{getTicketSold(tick._id)}</td>
                                                            <td className={`${!isRevenue ? 'd-none' : 'd-block'}`} scope="col">{parseInt(getTicketRevenue(tick._id)/100).toFixed(2)}</td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <p>No Data Found!</p>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default YourEvents