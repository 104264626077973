import PuffLoader from "react-spinners/PuffLoader";
import VendorSideBar from "./VendorSideBar";
import NavigationBar from "./NavigationBar";
import { useEffect, useState } from "react";
import { API_URL } from '../../config';
import axios from "axios";

const Payouts = () => {
    const user = JSON.parse(localStorage.getItem('VendorData'));
    const [isLoading, setIsLoading] = useState(false);
    const [newBankAc, setNewBankAc] = useState({
        bank_Account_type: "",
        beneficiar_name: "",
        bank_account_no: "",
        bank_name: "",
        IFSC_code: "",
    });
    const [errors, setErrors] = useState({});
    const [isValid, setIsValid] = useState(false);
    const [vendor, setVendor] = useState({});
    const [AdditionalBank, setVendorAdditionalBank] = useState([]);
    let account_count = 1;
    // Form Validation
    const validate = () => {
        const newErrors = {};

        if (!newBankAc.bank_Account_type) {
            newErrors.bank_Account_type = 'Bank account number is required';
        }

        if (!newBankAc.beneficiar_name) {
            newErrors.beneficiar_name = 'Beneficiary name is required';
        }

        if (!newBankAc.bank_account_no) {
            newErrors.bank_account_no = 'Bank account number is required';
        }

        if (!newBankAc.bank_name) {
            newErrors.bank_name = 'Bank name is required';
        }

        // IFSC code validation: should be exactly 11 alphanumeric characters
        if (!newBankAc.IFSC_code) {
            newErrors.IFSC_code = 'IFSC code is required';
        } else if (!/^[A-Za-z0-9]{11}$/.test(newBankAc.IFSC_code)) {
            newErrors.IFSC_code = 'Enter a valid IFSC code (11 alphanumeric characters)';
        }
        setErrors(newErrors);
        const isValidForm = Object.keys(newErrors).length === 0;
        setIsValid(isValidForm);
    };

    const handleSubmitForm = async () => {
        try {
            const saveBank = await axios.put(`${API_URL}/vendor/additional-bank/${user._id}`, newBankAc);
            if (saveBank.response === 200) {
                alert("Bank account added successfully");
                getVendorAdditionalBank();
            }
        } catch (error) {
            alert("Unable to add your Bank")
        }
    }

    const getVendorAdditionalBank = async () => {
        const VendorAdditionalBank = await axios.get(`${API_URL}/vendor/get-additional-bank/${user._id}`)
        console.log("VendorAdditionalBank", VendorAdditionalBank)
        if (VendorAdditionalBank.status === 200) {
            setVendorAdditionalBank(VendorAdditionalBank.data)
        }
    }
    useEffect(() => {
        console.log("user._id", user._id)
        const getVendor = async () => {
            setIsLoading(true)
            const VendorData = await axios.get(`${API_URL}/vendor/getVendor/${user._id}`)
            console.log("VendorData", VendorData)
            if (VendorData.status === 200) {
                setVendor(VendorData.data)
            }
            setIsLoading(false)
        }
        getVendor();
    }, [])

    const handleShowBankDetail = async () => {
        const VendorAdditionalBank = await axios.get(`${API_URL}/vendor/getVendor/${user._id}`)
        if (VendorAdditionalBank.status === 200 && VendorAdditionalBank.data) {
            setNewBankAc({
                bank_Account_type: VendorAdditionalBank.data.bank_Account_type,
                beneficiar_name: VendorAdditionalBank.data.beneficiar_name,
                bank_account_no: VendorAdditionalBank.data.bank_account_no,
                bank_name: VendorAdditionalBank.data.bank_name,
                IFSC_code: VendorAdditionalBank.data.IFSC_code,
            })
        }
    }
    const handleNewAddBankDetail = () => {
        setNewBankAc({
            bank_Account_type: "",
            beneficiar_name: "",
            bank_account_no: "",
            bank_name: "",
            IFSC_code: "",
        })
    }
    return (
        <>
            <NavigationBar />
            {
                isLoading ? (
                    <>
                        <div className='d-flex justify-content-center align-items-center' style={{ height: "50vh" }}>
                            <PuffLoader size={48} loading={isLoading} />
                        </div>
                    </>
                ) :
                    (
                        <div className="container-fluid">
                            <div className="row">
                                <VendorSideBar />
                                <div className="col-lg-10 col-md-10 col-12 px-2">
                                    <div className="mb-3">
                                        <div className='d-flex justify-content-between border-bottom border-primary'>
                                            <span className="vender-heading fw-semibold">Payouts</span>
                                        </div>
                                    </div>
                                    <div className="card mb-3">
                                        <div className="card-body d-flex justify-content-between">
                                            <p className="fw-semibold addbank-head">Added Bank Account ({account_count})</p>
                                            <button type="button" className="addbank-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={handleNewAddBankDetail}>Add Bank Account</button>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-body d-flex justify-content-between">
                                            <div className="card" data-bs-toggle="modal" data-bs-target="#VendorInitialBank" onClick={handleShowBankDetail} style={{ minWidth: "250px" }}>
                                                <div className="card-footer">
                                                    <span className="fw-bold">{vendor.bank_name}</span>
                                                    <div>
                                                        <p className="fw-semibold">{vendor.beneficiar_name}</p>
                                                        <p>
                                                            {vendor.bank_account_no
                                                                ? vendor.bank_account_no.slice(-4).padStart(vendor.bank_account_no.length, '*')
                                                                : '****'}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
            }
            {/* Modal for initial bank */}
            <div className="modal fade" id="VendorInitialBank" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="VendorInitialBankLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="VendorInitialBankLabel">Add Bank Account</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className='col-lg-6 col-md-6'>
                                    <div className="text-start mb-3">
                                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='bank_Account_type'>Account Type</label> <span className='text-danger fs-4'>*</span>
                                        <select
                                            disabled
                                            className='form-control border-primary'
                                            type='text'
                                            name='bank_Account_type'
                                            value={newBankAc.bank_Account_type}
                                            onChange={(e) => setNewBankAc({ ...newBankAc, bank_Account_type: e.target.value })}
                                        >
                                            <option value="" disabled>Select Account </option>
                                            <option value="current_acc">Current Account</option>
                                            <option value="saving_acc">Saving Account</option>
                                            <option value="salary_acc">Salary Account</option>
                                            <option value="nri_acc">NRI Account</option>
                                        </select>
                                        {errors.bank_Account_type && <p className='text-danger ms-3'>{errors.bank_Account_type}</p>}
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <div className="text-start mb-3">
                                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='beneficiar_name'>Beneficiary Name</label> <span className='text-danger fs-4'>*</span>
                                        <input
                                            disabled
                                            className='form-control border-primary'
                                            type='beneficiar_name'
                                            name='beneficiar_name'
                                            placeholder='Enter your Full Name'
                                            value={newBankAc.beneficiar_name}
                                            onChange={(e) => setNewBankAc({ ...newBankAc, beneficiar_name: e.target.value })}
                                        />
                                        {errors.beneficiar_name && <p className='text-danger ms-3'>{errors.beneficiar_name}</p>}
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <div className="text-start mb-3">
                                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='bank_account_no'>Account Number</label> <span className='text-danger fs-4'>*</span>
                                        <input
                                            disabled
                                            className='form-control border-primary'
                                            type='number'
                                            name='bank_account_no'
                                            placeholder='Enter account number'
                                            value={newBankAc.bank_account_no}
                                            onChange={(e) => setNewBankAc({ ...newBankAc, bank_account_no: e.target.value })}
                                        />
                                        {errors.bank_account_no && <p className='text-danger ms-3'>{errors.bank_account_no}</p>}
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <div className="text-start mb-3">
                                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='bank_name'>Bank Name</label> <span className='text-danger fs-4'>*</span>
                                        <input
                                            disabled
                                            className='form-control border-primary'
                                            type='text'
                                            name='bank_name'
                                            value={newBankAc.bank_name}
                                            onChange={(e) => setNewBankAc({ ...newBankAc, bank_name: e.target.value })}
                                        />
                                        {errors.bank_name && <p className='text-danger ms-3'>{errors.bank_name}</p>}
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <div className="text-start mb-3">
                                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='IFSC_code'>IFSC</label> <span className='text-danger fs-4'>*</span>
                                        <input
                                            disabled
                                            className='form-control border-primary'
                                            type='text'
                                            name='IFSC_code'
                                            placeholder='Enter IFSC'
                                            value={newBankAc.IFSC_code}
                                            onChange={(e) => setNewBankAc({ ...newBankAc, IFSC_code: e.target.value })}
                                        />
                                        {errors.IFSC_code && <p className='text-danger ms-3'>{errors.IFSC_code}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="modal-footer">
                            <button type="button" className="btn-close-modal" data-bs-dismiss="modal">Close</button>
                        </div> */}
                    </div>
                </div>
            </div>

            {/* <!-- Modal --> */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Add Bank Account</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className='col-lg-6 col-md-6'>
                                    <div className="text-start mb-3">
                                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='bank_Account_type'>Account Type</label> <span className='text-danger fs-4'>*</span>
                                        <select
                                            className='form-control border-primary'
                                            type='text'
                                            name='bank_Account_type'
                                            value={newBankAc.bank_Account_type}
                                            onChange={(e) => setNewBankAc({ ...newBankAc, bank_Account_type: e.target.value })}
                                        >
                                            <option value="" disabled>Select Account </option>
                                            <option value="current_acc">Current Account</option>
                                            <option value="saving_acc">Saving Account</option>
                                            <option value="salary_acc">Salary Account</option>
                                            <option value="nri_acc">NRI Account</option>
                                        </select>
                                        {errors.bank_Account_type && <p className='text-danger ms-3'>{errors.bank_Account_type}</p>}
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <div className="text-start mb-3">
                                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='beneficiar_name'>Beneficiary Name</label> <span className='text-danger fs-4'>*</span>
                                        <input
                                            className='form-control border-primary'
                                            type='beneficiar_name'
                                            name='beneficiar_name'
                                            placeholder='Enter your Full Name'
                                            value={newBankAc.beneficiar_name}
                                            onChange={(e) => setNewBankAc({ ...newBankAc, beneficiar_name: e.target.value })}
                                        />
                                        {errors.beneficiar_name && <p className='text-danger ms-3'>{errors.beneficiar_name}</p>}
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <div className="text-start mb-3">
                                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='bank_account_no'>Account Number</label> <span className='text-danger fs-4'>*</span>
                                        <input
                                            className='form-control border-primary'
                                            type='number'
                                            name='bank_account_no'
                                            placeholder='Enter account number'
                                            value={newBankAc.bank_account_no}
                                            onChange={(e) => setNewBankAc({ ...newBankAc, bank_account_no: e.target.value })}
                                        />
                                        {errors.bank_account_no && <p className='text-danger ms-3'>{errors.bank_account_no}</p>}
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <div className="text-start mb-3">
                                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='bank_name'>Bank Name</label> <span className='text-danger fs-4'>*</span>
                                        <input
                                            className='form-control border-primary'
                                            type='text'
                                            name='bank_name'
                                            value={newBankAc.bank_name}
                                            onChange={(e) => setNewBankAc({ ...newBankAc, bank_name: e.target.value })}
                                        />
                                        {errors.bank_name && <p className='text-danger ms-3'>{errors.bank_name}</p>}
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <div className="text-start mb-3">
                                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='IFSC_code'>IFSC</label> <span className='text-danger fs-4'>*</span>
                                        <input
                                            className='form-control border-primary'
                                            type='text'
                                            name='IFSC_code'
                                            placeholder='Enter IFSC'
                                            value={newBankAc.IFSC_code}
                                            onChange={(e) => setNewBankAc({ ...newBankAc, IFSC_code: e.target.value })}
                                        />
                                        {errors.IFSC_code && <p className='text-danger ms-3'>{errors.IFSC_code}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn-close-modal" data-bs-dismiss="modal">Close</button>
                            <button type="button" onClick={handleSubmitForm} className="btn-save-modal" data-bs-dismiss="modal">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Payouts;