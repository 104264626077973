import axios from 'axios';
import React, { useState } from 'react';
import { API_URL } from '../../config';
import { useNavigate } from 'react-router-dom';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { FiPlusCircle } from "react-icons/fi";
import SyncLoader from 'react-spinners/SyncLoader';

function AddOn({ onNext, eventDetail }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)
  const [additionalData, setAdditionalData] = useState({
    Event_genre: "",
    Event_lang: "",
    Age_restriction: 0,
    // Instagram_account: "",
    // Spotify_account: "",
    // picture: "",
    // custom_info: "",
    Venuepicture: "",
    artists: [
      {
        artist_name: "",
        artist_pic: ""
      }
    ]
  });

  const [artists, setArtists] = useState([
    { Artist: '', Artist_pic: null }
  ]);

  const [fileNames, setFileNames] = useState({
    // picture: "Upload file",
    Venuepicture: "Upload file"
  });

  const CustomerInfo = ["info1", "info2", "info3", "info4", "info5"];

  const handleFileSelect = (e, fieldName, index = null) => {
    if (index !== null) {
      // Handle artist's image
      const newArtists = [...artists];
      newArtists[index].Artist_pic = e.target.files[0];
      setArtists(newArtists);
    } else {
      // Handle other images
      const file = e.target.files[0];
      setAdditionalData({
        ...additionalData,
        [fieldName]: file
      });
      setFileNames({
        ...fileNames,
        [fieldName]: file ? file.name : "Upload file"
      });
    }
  };

  const handleArtistInputChange = (e, index) => {
    const { name, value } = e.target;
    const newArtists = [...artists];
    newArtists[index][name] = value;
    setArtists(newArtists);
  };

  const addArtistRow = () => {
    setArtists([...artists, { Artist: '', Artist_pic: null }]);
  };

  const handleChange = (tab) => {
    onNext(tab);
  };

  const handleSubmit = async (event) => {
    setIsLoading(true)
    event.preventDefault();
    try {
      const formData = new FormData();

      // Append artist pics
      artists.forEach((artist, index) => {
        if (artist.Artist_pic) {
          formData.append(`Artist_pic_${index}`, artist.Artist_pic);
        }
      });

      // Append other files
      // formData.append('picture', additionalData.picture);
      formData.append('Venuepicture', additionalData.Venuepicture);

      const response = await axios.post(`${API_URL}/image/file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        const uploadedArtistPics = response.data.files.artists.map((artist) => artist || '');

        // const uploadedPicture = response.data.files.picture || '';
        const uploadedVenuePicture = response.data.files.Venuepicture || '';

        const resData = await axios.post(`${API_URL}/api/addon`, {
          ...additionalData,
          artists: artists.map((artist, index) => ({
            ...artist,
            Artist_pic: uploadedArtistPics[index]
          })),
          // picture: uploadedPicture,
          Venuepicture: uploadedVenuePicture
        });

        const data = { addon_id: resData.data.data._id, event_id: eventDetail._id };
        await axios.put(`${API_URL}/api/addeventaddon`, data);

        alert("Your Event Added successfully");
        navigate("/vendor/dashboard");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      alert("Something went wrong");
    }finally{
      setIsLoading(false)
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className='addeventdetailDiv mt-3'>
          <div className='container'>
            <div className='container'>
              <div id="AddOn-detail mt-1">
                <div className='row mt-1'>
                  <div className='col-lg-6 col-md-6'>
                    {/* Event genre field */}
                    <div className="text-start mb-3">
                      <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='Event_genre'>Presented by :</label> <span className='text-danger fs-4'>*</span>
                      <input
                        className='form-control border-primary'
                        type='text'
                        name='Event_genre'
                        placeholder='Name of Event Presenter'
                        value={additionalData.Event_genre}
                        onChange={(e) => setAdditionalData({ ...additionalData, Event_genre: e.target.value })}
                      />
                    </div>
                  </div>

                  <div className='col-lg-6 col-md-6'>
                    {/* Event language field */}
                    <div className="text-start mb-3">
                      <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='Event_lang'>Event Language</label> <span className='text-danger fs-4'>*</span>
                      <input
                        className='form-control border-primary'
                        type='text'
                        name='Event_lang'
                        placeholder='Enter Event Language'
                        value={additionalData.Event_lang}
                        onChange={(e) => setAdditionalData({ ...additionalData, Event_lang: e.target.value })}
                      />
                    </div>
                  </div>

                  <div className='col-lg-6 col-md-6'>
                    {/* Age restriction field */}
                    <div className="text-start mb-3">
                      <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='Age_restriction'>Age Restriction</label> <span className='text-danger fs-4'>*</span>
                      <input
                        className='form-control border-primary'
                        type='number'
                        name='Age_restriction'
                        placeholder='Enter age restriction'
                        value={additionalData.Age_restriction}
                        onChange={(e) => setAdditionalData({ ...additionalData, Age_restriction: e.target.value })}
                      />
                    </div>
                  </div>

                  <div className='col-lg-6 col-md-6'>
                    <div className="text-start mb-3">
                      <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='Venuepicture'> <span className='text-danger fs-4'></span>
                        Add Venue Layout
                      </label>
                      <div className="file-input-container">
                        <input
                          type="file"
                          id="Venuepicture"
                          name="Venuepicture"
                          onChange={(e) => handleFileSelect(e, "Venuepicture")}
                        />
                        <label htmlFor="Venuepicture" className="file-input-label border-primary">
                          <span className="upload-text">{fileNames.Venuepicture}</span>
                          <span className="upload-icon text-primary"><AiOutlineCloudUpload size={25} /></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Dynamic Artist Fields */}
                {artists.map((artist, index) => (
                  <div className="row mt-1" key={index}>
                    <div className='col-lg-6 col-md-6'>
                      {/* Artist Name Field */}
                      <div className="text-start mb-3">
                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor={`Artist_${index}`}>Artist/ Performer-{index + 1}</label> <span className='text-danger fs-4'>*</span>
                        <input
                          className='form-control border-primary'
                          type='text'
                          name='Artist'
                          placeholder='Enter Artist/ Performer Name'
                          value={artist.Artist}
                          onChange={(e) => handleArtistInputChange(e, index)}
                        />
                      </div>
                    </div>

                    <div className='col-lg-6 col-md-6'>
                      {/* Artist Photo Field */}
                      <div className="text-start mb-3">
                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor={`Artist_pic_${index}`}>
                          Add Artist Picture-{index + 1} <span className='text-danger fs-4'>*</span>
                        </label>
                        <div className="file-input-container">
                          <input
                            type="file"
                            id={`Artist_pic_${index}`}
                            name={`Artist_pic_${index}`}
                            onChange={(e) => handleFileSelect(e, "Artist_pic", index)}
                          />
                          <label htmlFor={`Artist_pic_${index}`} className="file-input-label border-primary">
                            <span className="upload-text">{artist.Artist_pic ? artist.Artist_pic.name : 'Upload file'}</span>
                            <span className="upload-icon text-primary"><AiOutlineCloudUpload size={25} /></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="mt-1">
                  <button type="button" className="addMore-Btn" onClick={addArtistRow}> <FiPlusCircle className='mb-1' /> Add More Artist</button>
                </div>

                {/* Other fields */}
                {/* <div className='row mt-1'>
                <div className='col-lg-6 col-md-6 mt-1'>
                  <div className="text-start mb-3">
                    <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='Instagram_account'>Artist's Instagram username</label>
                    <input
                      className='form-control border-primary'
                      type='text'
                      name='Instagram_account'
                      placeholder="Enter Artist's Instagram account"
                      value={additionalData.Instagram_account}
                      onChange={(e) => setAdditionalData({ ...additionalData, Instagram_account: e.target.value })}
                    />
                  </div>
                </div>

                <div className='col-lg-6 col-md-6 mt-1'>
                  <div className="text-start mb-3">
                    <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='Spotify_account'>Artist's Spotify Account</label>
                    <input
                      className='form-control border-primary'
                      type='text'
                      name='Spotify_account'
                      placeholder="Enter Artist's Spotify Account"
                      value={additionalData.Spotify_account}
                      onChange={(e) => setAdditionalData({ ...additionalData, Spotify_account: e.target.value })}
                    />
                  </div>
                </div>
              </div> */}

                {/* <div className='row mt-1'>
                <div className='col-lg-6 col-md-6 mt-1'>
                  <div className="text-start mb-3">
                    <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='picture'>
                      Add Picture <span className='text-danger fs-4'>*</span>
                    </label>
                    <div className="file-input-container">
                      <input
                        type="file"
                        id="picture"
                        name="picture"
                        onChange={(e) => handleFileSelect(e, "picture")}
                      />
                      <label htmlFor="picture" className="file-input-label border-primary">
                        <span className="upload-text">{fileNames.picture}</span>
                        <span className="upload-icon text-primary"><AiOutlineCloudUpload size={25} /></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 mt-1'>
                  <div className="text-start mb-3">
                    <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='custom_info'>Add Custom Information</label>
                    <span className='text-danger fs-4'>*</span>
                    <select
                      className='form-control border-primary'
                      name='custom_info'
                      placeholder='Enter Event Category'
                      value={additionalData.custom_info}
                      onChange={(e) => setAdditionalData({ ...additionalData, custom_info: e.target.value })}
                    >
                      <option value='default' disabled>Select</option>
                      {CustomerInfo.map((info, index) => (
                        <option value={info} key={index}>{info}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className='adminfooterDiv text-end py-3 mt-5'>
          <button type='button' className={`btn backbtn`} onClick={() => handleChange("ticket-detail")}>Back</button>
          <button type='submit' className='btn basicnextbtn' disabled={isLoading}>
          {isLoading ? <SyncLoader animation="border" color="#FFFF" size="7" speedMultiplier={1} margin={4} /> : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddOn;
