import React from 'react'
import './NavigationBar.css'
import Logo from '../../Assets/logo_197x53-01.png'
import { TbTransactionRupee, TbUser } from "react-icons/tb";
import { BsList } from 'react-icons/bs';
import { TbArrowLeftFromArc } from "react-icons/tb";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { PiGridFour } from "react-icons/pi";
import { FaRegHeart } from "react-icons/fa";
import { MdOutlineMessage } from "react-icons/md";
import { VscChecklist } from "react-icons/vsc";
import { AiOutlineDashboard } from "react-icons/ai";
import { BsQrCodeScan } from "react-icons/bs";
import { IoIosAddCircleOutline } from "react-icons/io";

function NavigationBar() {
    const user = JSON.parse(localStorage.getItem('VendorData'));
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.clear();
        navigate('/');
    };
    return (
        <>
            <div className='nav d-flex justify-content-between align-items-center' id="largesScreenDevice">
                <div className='logoforVender'>
                    {/* <Link to="/vendor/dashboard"> */}
                        <img className='w-100 h-100' src={Logo} alt="Logo" />
                    {/* </Link> */}
                </div>
                <div className='me-5'>
                  {
                    user && (  <Link className="link-light  link-underline link-underline-opacity-0 userinfo fw-semibold" to="/vendor/dashboard"><span><TbUser className='mb-2' size={25} /></span> <span>{user ? user?.name : "Guest"}</span></Link>)
                  }
                </div>
            </div>

            {/* Navbar for smallScreenDevice */}
            <div className='nav' id='SmallScreenDevice'>
                <div className='navbar'>
                    <div className="dropdown togglebtn">
                        <BsList className='dropdown-toggle fs-1 ms-2 fw-bold'
                            data-bs-toggle="dropdown"
                            aria-expanded="false" aria-controls="responsiveNavbar"
                        />
                        <ul className="dropdown-menu collapseNavbar" style={{height:"100vh", width:"30vh", marginTop:"0.7rem"}}>
                            {/* <div className='d-flex justify-content-end pe-2'>
                                <FiX className='text-dark' size={30} />
                            </div> */}
                            <li>
                                <div className='sidenavcontent'>
                                    <ul>
                                        <li >
                                            <Link className='link-dark link-underline-opacity-0 fw-semibold' to='/vendor/dashboard'>
                                                <AiOutlineDashboard className='sidebar-icon' />
                                                <span className='sidebar-tab'>Dashboard</span>
                                            </Link>
                                        </li>
                                        <li className='nav-content' style={{ marginTop: "23px" }}>
                                            <Link className='link-dark link-underline-opacity-0 fw-semibold' to='/vendor/yourevents'>
                                                <PiGridFour className='sidebar-icon' />
                                                <span className='sidebar-tab'>Your Events</span>
                                            </Link>
                                        </li>
                                        <li className='nav-content' style={{ marginTop: "23px" }}>
                                            <Link className='link-dark link-underline-opacity-0 fw-semibold' to='/vendor/ticketscan'>
                                                <BsQrCodeScan className='sidebar-icon' />
                                                <span className='sidebar-tab'>Tickets Scanner</span>
                                            </Link>
                                        </li>
                                        <li className='nav-content' style={{ marginTop: "23px" }}>
                                            <Link className='link-dark link-underline-opacity-0 fw-semibold' to='/vendor/create-event'>
                                                <IoIosAddCircleOutline className='sidebar-icon' />
                                                <span className='sidebar-tab'>Create Event</span>
                                            </Link>

                                        </li>
                                        <li className='nav-content' style={{ marginTop: "23px" }}>
                                            <Link className='link-dark link-underline-opacity-0 fw-semibold' to='/vendor/campaigns'>
                                                <VscChecklist className='sidebar-icon' />
                                                <span className='sidebar-tab'>Campaigns</span>
                                            </Link>

                                        </li>
                                        <li className='nav-content' style={{ marginTop: "23px" }}>
                                            <Link className='link-dark link-underline-opacity-0 fw-semibold' to='/vendor/payouts'>
                                                <TbTransactionRupee className='sidebar-icon' />
                                                <span className='sidebar-tab'>Payouts</span>
                                            </Link>

                                        </li>
                                        <li className='nav-content' style={{ marginTop: "23px" }}>
                                            <Link className='link-dark link-underline-opacity-0 fw-semibold' to='/' onClick={handleLogout}>
                                                <TbArrowLeftFromArc className='sidebar-icon' />
                                                <span className='sidebar-tab'>Logout</span>
                                            </Link>

                                        </li>

                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className='logoforVender'>
                        <Link to="/profile">
                            <img className='w-100 h-100' src={Logo} alt="Logo" />
                        </Link>
                    </div>
                    <div className='searchbtn'>
                        <TbUser className="searchIcon" size={30} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default NavigationBar
