import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { AiFillLock, AiOutlineCloudUpload } from "react-icons/ai";
import { Globe, Lock } from 'react-feather';
import { API_URL } from '../../config';
import { Link } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import SyncLoader from "react-spinners/SyncLoader";

function AddEventDetails({ onNext }) {
    const vendor = JSON.parse(localStorage.getItem('VendorData'));
    const [isActive, setIsActive] = useState({
        form: "basics",
    });
    const [isChecked, setIsChecked] = useState({
        Venuedecided: false,
        locationManual: false,
    });
    const [uploaded_image, setUploaded_image] = useState("");
    // const [categoryList, SetCategoryList] = useState([]);
    const categoryList = ["Navaratri", "Live Music", "Experiences", "Conferences", "Workshops", "Comedy"]
    // const repeatedEvents = ["Re1", "Re2", "Re3", "Re4"];
    const [CityList, setCityList] = useState([]);
    // all fields
    const [formData, setFormData] = useState({
        event_name: '',
        category: '',
        // event_visibility: 'public',
        event_starts_date: '',
        event_starts_Time: '',
        event_schedule_Dates: [],
        event_ends_date: '',
        event_ends_Time: '',
        isEvent_repeat: false,
        event_repeats: '',
        event_city: '',
        event_venue: '',
        venue_latitude: '',
        venue_longitude: '',
        event_description: '',
        termsCondition: '',
        guidelines: '',
        cover_image: '',
        isAllSession: false,
        vendor_id: vendor._id || vendor.uid
    });
    const [errors, setErrors] = useState({});
    const [isValid, setIsValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [imgloading, setImgloading] = useState(false);
    const [isUploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    // const [event_schedule_Dates, setSelectedDates] = useState([]);
    // Form Validation
    const validate = () => {
        const newErrors = {};
        if (isActive.form === "basics") {
            if (!formData.event_name) {
                newErrors.event_name = 'Event name is required';
            }
            if (!formData.category) {
                newErrors.category = 'Category is required';
            }
            if (!formData.event_starts_date) {
                newErrors.event_starts_date = 'Event start date is required';
            }
            if (!formData.event_ends_date) {
                newErrors.event_ends_date = 'Event end date is required';
            }
            setErrors(newErrors);
            const isValidForm = Object.keys(newErrors).length === 0;
            setIsValid(isValidForm);
            return isValidForm;
        } else if (isActive.form === "location") {
            if (!formData.event_city) {
                newErrors.event_city = 'Event city is required';
            }
            setErrors(newErrors);
            const isValidForm = Object.keys(newErrors).length === 0;
            setIsValid(isValidForm);
            return isValidForm;
        } else if (isActive.form === "description") {
            if (!formData.event_description) {
                newErrors.event_description = 'Event description is required';
            }
            setErrors(newErrors);
            const isValidForm = Object.keys(newErrors).length === 0;
            setIsValid(isValidForm);
            return isValidForm;
        }
    };
    // Functionality to select the file
    const handleFileSelection = (event) => {
        const file = event.target.files[0];
        if (file) {
            setUploaded_image(file.name);
            setFormData({
                ...formData,
                cover_image: file,
            });
            setUploadButtonDisabled(false);  // Enable the upload button after selecting a file
        }
    };

    // Functionality to upload the selected file
    const handleFileUpload = async () => {
        setImgloading(true)
        if (!formData.cover_image) {
            alert('Please select a file');
            return;
        }

        const fd = new FormData();
        fd.append('image', formData.cover_image);

        try {
            const response = await axios.post(`${API_URL}/image/upload`, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                const uploadedFilename = response.data.file.filename;
                setFormData(prevFormData => ({
                    ...prevFormData,
                    cover_image: uploadedFilename,
                }));
                alert('File uploaded successfully');
                setUploadButtonDisabled(true);  // Disable the upload button after successful upload
            }
        } catch (error) {
            alert('File upload failed. Please try again.');
        }finally{
            setImgloading(false)
        }
    };


    const FetchCategory = async () => {
        //     const Cate = await axios.get(`${API_URL}/api/category`);
        //     if (Cate.status === 200) {
        //         SetCategoryList(Cate.data);
        //     } else {
        //         SetCategoryList(["No Cities are available"]);
        //     }
    }
    useEffect(() => {
        // Immediately Invoke Function Expression
        (async () => {
            const cities = await axios.get(`${API_URL}/api/city`);
            if (cities.status === 200) {
                setCityList(cities.data);
            } else {
                setCityList(["No Cities are available"]);
            }
        })();
        FetchCategory();
    }, []);

    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault();
        try {
            if (isValid) {
                const result = await axios.post(`${API_URL}/api/addevent`, formData);
                if (result.status === 200) {
                    onNext("ticket-detail", result.data.event, isValid);
                }
                else {
                    alert(result.data.message);
                    setIsActive({ ...isActive, form: "basics" });
                }
            }
        } catch (error) {
            alert(error.data.message);
            setIsActive({ ...isActive, form: "basics" });
        }finally{
            setLoading(false)
        }
    };

    const handleNext = (nextForm) => {
        setLoading(true)
        if (validate()) {
            setIsActive({ ...isActive, form: nextForm });
            console.log(isActive);
        }
        setLoading(false)
    };
    const handletabs = (nextForm) => {
        setIsActive({ ...isActive, form: nextForm });
    };

    const isDateInRange = (date) => {
        if (!formData.event_starts_date || !formData.event_ends_date) {
            return false; // If no dates are selected, disable all
        }
        const startDate = new Date(formData.event_starts_date);
        const endDate = new Date(formData.event_ends_date);
        // Include startDate and endDate in the range
        return date >= startDate && date <= endDate;
    };

    // Handle date selection on the calendar
    const handleDateSelection = (date) => {
        const dateStr = date.toDateString();
        if (formData.event_schedule_Dates.includes(dateStr)) {
            // Remove the date if it's already selected
            setFormData((prev) => ({
                ...prev,
                event_schedule_Dates: prev.event_schedule_Dates.filter((d) => d !== dateStr),
            }));
        } else {
            // Add the new date to the selected dates array
            setFormData((prev) => ({
                ...prev,
                event_schedule_Dates: [...prev.event_schedule_Dates, dateStr],
            }));
        }
    };

    // Effect to handle date range updates
    useEffect(() => {
        // Reset selected dates if the start or end date changes
        setFormData((prev) => ({
            ...prev,
            event_schedule_Dates: prev.event_schedule_Dates.filter(dateStr => {
                const date = new Date(dateStr);
                return isDateInRange(date); // Only keep dates that are still in the range
            })
        }));
    }, [formData.event_starts_date, formData.event_ends_date]);


    return (
        <div>
            <div>
                <div className='subnavbar'>
                    <div className='d-flex justify-content-between'>
                        <span className={`subnavitem fw-semibold ${isActive.form === "basics" ? "isActiveForm" : "defalut"}`} onClick={() => handletabs("basics")}>Basics</span>
                        <div className={`border-dark border-line w-50 mx-lg-2 mx-md-2 mx-1 mt-lg-3 ${isActive.form === "location" ? "border-info" : "border-line"}`}></div>
                        <span className={`subnavitem fw-semibold ${isActive.form === "location" ? "isActiveForm" : "defalut"}`} onClick={() => handletabs("location")}>Location</span>
                        <div className={`border-dark border-line w-50 mx-lg-2 mx-md-2 mx-1 mt-lg-3 ${isActive.form === "description" ? "border-info" : "border-line"}`}></div>
                        <span className={`subnavitem fw-semibold ${isActive.form === "description" ? "isActiveForm" : "defalut"}`} onClick={() => handletabs("description")}>Description</span>
                        <div className={`border-dark border-line w-50 mx-lg-2 mx-md-2 mx-1 mt-lg-3 ${isActive.form === "photos" ? "border-info" : "border-line"}`}></div>
                        <span className={`subnavitem fw-semibold ${isActive.form === "photos" ? "isActiveForm" : "defalut"}`} onClick={() => handletabs("photos")}>Photos</span>
                    </div>
                </div>
                {/* Add  Event  Details */}
                <form onSubmit={handleSubmit}>
                    {/* Basics Detail form */}
                    <div id="basics-detail" className={`${isActive.form === "basics" ? "d-block" : "d-none"}`}>
                        <div className='addeventdetailDiv'>
                            <div className='container'>
                                <div className='container'>
                                    <div className='mt-lg-5 mt-md-5 mt-4'>
                                        <div className="text-start mb-md-3 mb-sm-0 mb-0">
                                            <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='event_name'>Event Name</label> <span className='text-danger fs-4'>*</span>
                                            <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                                                type='text'
                                                name='event_name'
                                                placeholder='Enter Event Name'
                                                value={formData.event_name}
                                                onChange={(e) => {
                                                    setFormData({
                                                        ...formData,
                                                        event_name: e.target.value
                                                    });
                                                }}
                                            />
                                            {errors.event_name && <p className='text-danger ms-3'>{errors.event_name}</p>}
                                        </div>
                                        <div className="text-start mb-md-3 mb-sm-2 mb-1">
                                            <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='event_name'>Event Category</label> <span className='text-danger fs-4'>*</span>
                                            <select
                                                className='form-control'
                                                style={{ borderColor: "#2C9CF0" }}
                                                name='category'
                                                placeholder='Enter Event Category'
                                                required
                                                onChange={(e) => {
                                                    setFormData({
                                                        ...formData,
                                                        category: e.target.value
                                                    });
                                                }}
                                            >
                                                <option value='default' selected disabled>Select Category</option>
                                                {
                                                    categoryList
                                                        .sort((a, b) => a.localeCompare(b)) // Sort categories alphabetically (A-Z)
                                                        .map((category, index) => {
                                                            return (
                                                                <option value={category} key={index}>{category}</option>
                                                            );
                                                        })
                                                }
                                            </select>

                                            {errors.category && <p className='text-danger ms-3'>{errors.category}</p>}
                                        </div>
                                        {/* <div className="text-start mb-md-3 mb-sm-2 mb-1">
                                            <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='event_name'>Event Visibility</label>
                                            <div>
                                                <button type='button'
                                                    className={`"btn eveVisibilitybtn border-primary  me-2 px-4" ${formData.event_visibility === "public" ? "btn btn-primary" : "btn"}`}
                                                    onClick={() => { setFormData({ ...formData, event_visibility: "public" }) }} >
                                                    <Globe className='mb-1' size={15} /> Public
                                                </button>
                                                <button type='button'
                                                    className={`"btn eveVisibilitybtn border-primary me-2 px-4" ${formData.event_visibility === "private" ? "btn btn-primary" : "btn"}`}
                                                    onClick={() => { setFormData({ ...formData, event_visibility: "private" }) }}>
                                                    <AiFillLock className='mb-1' size={15} /> Private
                                                </button>
                                            </div>
                                        </div> */}
                                        <div className='row mb-3'>
                                            <div className="col-lg-6 text-start">
                                                <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='event_starts'>Event Starts from</label> <span className='text-danger fs-4'>*</span>
                                                <div className='d-flex px-1'>
                                                    <input className='form-control  me-3' style={{ borderColor: "#2C9CF0" }} required
                                                        type='date'
                                                        name='event_starts_date'
                                                        value={formData.event_starts_date}
                                                        min={new Date().toISOString().split('T')[0]} // Set minimum date to today
                                                        onChange={(e) => {
                                                            setFormData({
                                                                ...formData,
                                                                event_starts_date: e.target.value
                                                            });
                                                        }}
                                                    />
                                                    <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                                                        type='time'
                                                        name='event_starts_Time'
                                                        value={formData.event_starts_Time}
                                                        onChange={(e) => {
                                                            setFormData({
                                                                ...formData,
                                                                event_starts_Time: e.target.value
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                {errors.event_starts_date && <p className=' text-danger ms-3'>{errors.event_starts_date}</p>}
                                            </div>
                                            <div className="col-lg-6 text-start">
                                                <label className="poppins20 fw-semibold ms-1 mb-2 mt-2" htmlFor='event_ends'>Event Ends at</label> <span className='text-danger fs-4'>*</span>
                                                <div className='d-flex px-1'>
                                                    <input className='form-control  me-3' style={{ borderColor: "#2C9CF0" }}
                                                        type='date'
                                                        name='event_ends_date'
                                                        value={formData.event_ends_date}
                                                        min={formData.event_starts_date || new Date().toISOString().split('T')[0]} // Set minimum date
                                                        onChange={(e) => {
                                                            setFormData({
                                                                ...formData,
                                                                event_ends_date: e.target.value
                                                            });
                                                        }}
                                                    />
                                                    <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                                                        type='time'
                                                        name='event_ends_Time'
                                                        value={formData.event_ends_Time}
                                                        onChange={(e) => {
                                                            setFormData({
                                                                ...formData,
                                                                event_ends_Time: e.target.value
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                {errors.event_ends_date && <p className=' text-danger ms-3'>{errors.event_ends_date}</p>}
                                                {/* <div className='text-end pe-5 mt-2'>
                                                    <input type="checkbox" name="show_end_time"
                                                    /> <span className='show'>Show end time</span>
                                                </div> */}
                                            </div>
                                            <div className="col-lg-6 text-start mt-1">
                                                <input
                                                    className='fs-1'
                                                    type="checkbox"
                                                    checked={formData.isAllSession}
                                                    onChange={() => setFormData({ ...formData, isAllSession: !formData.isAllSession })}
                                                /> Pass For All Season
                                            </div>
                                        </div>
                                        {/* calender */}
                                        {/* <div className='row mb-3'>
                                            <div className='col-lg-6'>
                                                <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='event_repeats'>Select Dates</label> <span className='fs-6'>(*if event schedule on alternative days)</span>
                                                <Calendar
                                                    onChange={handleDateSelection}
                                                    value={formData.event_schedule_Dates.map(date => new Date(date))} // Set selected dates for the calendar
                                                    tileClassName={({ date }) => {
                                                        const dateStr = date.toDateString();
                                                        return formData.event_schedule_Dates.includes(dateStr) ? 'highlight' : '';
                                                    }}
                                                    onClickDay={handleDateSelection}
                                                    tileDisabled={({ date }) => !isDateInRange(date)} // Disable dates outside the range
                                                />

                                            </div>
                                            <div className='col-lg-6'>
                                                <h5>Selected Dates:</h5>
                                                <ul>
                                                    {
                                                        formData.event_schedule_Dates && formData.event_schedule_Dates > 0 ?
                                                            <>
                                                                {formData.event_schedule_Dates.map((date, index) => (
                                                                    <li key={index}>{date}</li>
                                                                ))}
                                                            </> : ""
                                                    }

                                                </ul>
                                            </div>
                                        </div> */}



                                        {/* <div className='text-start fw-bold mb-3'>
                                            <input
                                                className='fs-1'
                                                type="checkbox"
                                                checked={formData.isEvent_repeat}
                                                onChange={() => setFormData({ ...formData, isEvent_repeat: !formData.isEvent_repeat })}
                                            /> This is a repeat event
                                        </div>
                                        <div className={`text-start mb-3 ${formData.isEvent_repeat ? "d-block" : "d-none"}`}>
                                            <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='event_repeats'>Event Repeats</label>
                                            <select className='form-control' style={{borderColor:"#2C9CF0"}} name='event_repeats' placeholder='select'
                                                onChange={(e) => {
                                                    setFormData({
                                                        ...formData,
                                                        event_repeats: e.target.value
                                                    });
                                                }}>
                                                <option value='default' selected disabled>Select Event</option>
                                                {
                                                    repeatedEvents.map((event, index) => {
                                                        return (
                                                            <option value={event} key={index}>{event}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='adminfooterDiv'>
                            <button type='button' 
                            className="btn btn-primary basicnextbtn" 
                            onClick={() => handleNext("location")}
                            disabled={loading}>
                            {loading ? <SyncLoader animation="border" color="#FFFF" size="7" speedMultiplier={1} margin={4} /> : "Next"}
                            </button>
                        </div>
                    </div>

                    {/* Location Form */}
                    <div id="location-detail" className={`${isActive.form === "location" ? "d-block" : "d-none"}`}>
                        <div className='addeventdetailDiv'>
                            <div className='container'>
                                <div className='container'>
                                    <div>
                                        <div className="mt-lg-5 mt-md-5 mt-4">
                                            <div className="text-start mb-lg-4 mb-md-3 mb-3">
                                                <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='event_city'>Event City</label> <span className='text-danger fs-4'>*</span>
                                                <select
                                                    className='form-control'
                                                    style={{ borderColor: "#2C9CF0" }}
                                                    name='event_city'
                                                    placeholder='Enter Event City Name'
                                                    onChange={(e) => {
                                                        setFormData({
                                                            ...formData,
                                                            event_city: e.target.value
                                                        });
                                                    }}
                                                    required
                                                >
                                                    <option value='default' selected disabled>Select City</option>
                                                    {
                                                        CityList
                                                            .sort((a, b) => a.localeCompare(b)) // Sort cities alphabetically (A-Z)
                                                            .map((city, index) => {
                                                                return (
                                                                    <option value={city} key={index}>{city}</option>
                                                                );
                                                            })
                                                    }
                                                </select>

                                                {errors.event_city && <p className='text-danger ms-3'>{errors.event_city}</p>}
                                            </div>
                                            <div className={`text-start mb-lg-4 mb-md-3 mb-3 ${!isChecked.Venuedecided ? "d-block" : "d-none"}`}>
                                                <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='event_venue'>Event Venue</label>
                                                <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                                                    type='text'
                                                    name='event_venue'
                                                    placeholder='Enter Event Name'
                                                    value={formData.event_venue}
                                                    onChange={(e) => {
                                                        setFormData({
                                                            ...formData,
                                                            event_venue: e.target.value
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className='d-flex justify-content-between pe-5 mb-lg-4 mb-md-3 mb-3'>
                                                <div className='ps-2'>
                                                    <input type="checkbox" name="Venuedecided" checked={isChecked.Venuedecided}
                                                        onChange={() => { setIsChecked({ ...isChecked, Venuedecided: !isChecked.Venuedecided }) }}
                                                    /> <span className='poppins20 fw-semibold'> Venue not decided? </span>
                                                </div>
                                                {/* <div className=' fw-bold pe-5'>
                                    <input type="checkbox" name="locationManual" checked={isChecked.locationManual}
                                        onChange={() => { setIsChecked({ ...isChecked, locationManual: !isChecked.locationManual }) }}
                                    /> Enter Location Manually
                                </div> */}
                                            </div>

                                            {/* <div className={` venuelatitude mb-lg-4 mb-md-3 mb-3 pe-lg-5 pe-0 ${!isChecked.Venuedecided ? "d-block" : "d-none"}`}>
                                                <div className="text-start mb-3">
                                                    <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='venue_latitude'>Event Venue Latitude</label>
                                                    <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                                                        type='text'
                                                        name='venue_latitude'
                                                        placeholder='Enter Event Name'
                                                        value={formData.venue_latitude}
                                                        onChange={(e) => {
                                                            setFormData({
                                                                ...formData,
                                                                venue_latitude: e.target.value
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="text-start mb-4 me-lg-5 me-0 pe-0 pe-lg-5">
                                                    <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='venue_longitude'>Event Venue Longitude</label>
                                                    <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                                                        type='text'
                                                        name='venue_longitude'
                                                        placeholder='Enter Event Name'
                                                        value={formData.venue_longitude}
                                                        onChange={(e) => {
                                                            setFormData({
                                                                ...formData,
                                                                venue_longitude: e.target.value
                                                            });
                                                        }}
                                                    />
                                                </div>

                                            </div> */}
                                        </div>
                                        {/* Enter Manually Location Checked  */}
                                        {/* <div className={`mt-3 mx-lg-5 px-lg-5 ${isChecked.locationManual ? "d-block" : "d-none"}`} id="manual_location">
                            <div className='row'>
                                <div className='col-lg-6 pe-5'>
                                    <div className="text-start mb-3">
                                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='event_venue'>Event Venue</label>
                                        <input className='form-control  style={{borderColor:"#2C9CF0"}}mb-3'
                                            type='text'
                                            name='event_venue'
                                            placeholder='Name of the venue'
                                            value={formData.event_venue}
                                            onChange={(e) => {
                                                setFormData({
                                                    ...formData,
                                                    event_venue: e.target.value
                                                });
                                            }}
                                        />
                                        <input className='form-control  style={{borderColor:"#2C9CF0"}}mb-3'
                                            type='text'
                                            name='event_venue'
                                            placeholder='Street 1'
                                            value={formData.event_venue}
                                            onChange={(e) => {
                                                setFormData({
                                                    ...formData,
                                                    event_venue: e.target.value
                                                });
                                            }}
                                        />
                                        <input className='form-control  style={{borderColor:"#2C9CF0"}}mb-3'
                                            type='text'
                                            name='event_venue'
                                            placeholder='Street 2'
                                            value={formData.event_venue}
                                            onChange={(e) => {
                                                setFormData({
                                                    ...formData,
                                                    event_venue: e.target.value
                                                });
                                            }}
                                        />
                                        <select className='form-control  style={{borderColor:"#2C9CF0"}}mb-3' name='event_city' placeholder='City'
                                            onChange={(e) => {
                                                setFormData({
                                                    ...formData,
                                                    event_city: e.target.value
                                                });
                                            }}>
                                            <option value='default' selected disabled>Select City</option>
                                            {
                                                CityList.map((city, index) => {
                                                    return (
                                                        <option value={city} key={index}>{city}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <input type='radio' name='' /> Reset Loaction
                                    </div>
                                </div>
                                <div className='col-lg-6 text-start ps-5'>
                                    <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='event_venue'>Event Venue</label>
                                    <div className='map-location w-100 border border-dark mb-3' style={{ height: "200px" }}>
                                        Location Map Block 
                                    </div>
                                    <div><MapPin width={15} /> <span>Drag and drop marker to adjust location</span></div>
                                </div>
                            </div>
                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='adminfooterDiv'>
                            {!isChecked.locationManual ? <button type='button'
                                className={`btn backbtn border-white `}
                                onClick={() => { setIsActive({ ...isActive, form: "basics" }) }}
                            >Back</button> : ''}
                            <button type='button' className='btn basicnextbtn btn-primary' onClick={() => handleNext("description")} disabled={loading}>
                            {loading ? <SyncLoader animation="border" color="#FFFF" size="7" speedMultiplier={1} margin={4} /> : "Next"}</button>
                        </div>
                    </div>

                    {/* Description Form */}
                    <div id="description" className={`${isActive.form === "description" ? "d-block" : "d-none"}`}>
                        <div className='addeventdetailDiv'>
                            <div className='container'>
                                <div className='container'>
                                    <div className='des'>
                                        <div className='my-1'>
                                            <div className="text-start mb-3">
                                                <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='event_description'>About Event</label> <span className='text-danger fs-4'>*</span>
                                                <textarea className='form-control' required
                                                    type='text'
                                                    name='event_description'
                                                    placeholder='Enter Event Description'
                                                    value={formData.event_description}
                                                    onChange={(e) => {
                                                        setFormData({
                                                            ...formData,
                                                            event_description: e.target.value
                                                        });
                                                    }}
                                                    style={{ height: "98px", borderColor: "#2C9CF0" }}></textarea>
                                            </div>
                                            {errors.event_description && <p className='text-danger ms-3'>{errors.event_description}</p>}
                                        </div>
                                        <div className='my-1'>
                                            <div className="text-start mb-3">
                                                <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='termsCondition'>Terms & Conditions</label> <span className='text-danger fs-4'>*</span>
                                                <textarea className='form-control' required
                                                    type='text'
                                                    name='termsCondition'
                                                    placeholder='Enter Terms & Conditions'
                                                    value={formData.termsCondition}
                                                    onChange={(e) => {
                                                        setFormData({
                                                            ...formData,
                                                            termsCondition: e.target.value
                                                        });
                                                    }}
                                                    style={{ height: "98px", borderColor: "#2C9CF0" }}></textarea>
                                            </div>
                                            {errors.termsCondition && <p className='text-danger ms-3'>{errors.termsCondition}</p>}
                                        </div>
                                        <div className='my-1'>
                                            <div className="text-start mb-3">
                                                <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='guidelines'>Guidelines</label> <span className='text-danger fs-4'>*</span>
                                                <textarea className='form-control' required
                                                    type='text'
                                                    name='guidelines'
                                                    placeholder='Enter Guidelines'
                                                    value={formData.guidelines}
                                                    onChange={(e) => {
                                                        setFormData({
                                                            ...formData,
                                                            guidelines: e.target.value
                                                        });
                                                    }}
                                                    style={{ height: "98px", borderColor: "#2C9CF0" }}></textarea>
                                            </div>
                                            {errors.guidelines && <p className='text-danger ms-3'>{errors.guidelines}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='adminfooterDiv'>
                            <button type='button' className='btn backbtn border-white' onClick={() => { setIsActive({ ...isActive, form: "location" }); setIsChecked({ ...isChecked, locationManual: !isChecked.locationManual }) }}>Back</button>
                            <button type='button' className='btn basicnextbtn border-white' onClick={() => handleNext("photos")} disabled={loading}>
                            {loading ? <SyncLoader animation="border" color="#FFFF" size="7" speedMultiplier={1} margin={4} /> : "Next"}</button>
                        </div>
                    </div>

                    {/* Upload Photo Form */}
                    {/* <div id="eventPhotos" className={`${isActive.form === "photos" ? "d-block" : "d-none"}`}>
                        <div className='addeventdetailDiv'>
                            <div className='container'>
                                <div className='container'>
                                    <div  >
                                        <div className='mt-3'>
                                            <div className="text-center mb-3">
                                                <label className="poppins20 fw-semibold ms-1 mb-2 mt-3 text-center" htmlFor='cover_image'>Cover Image</label>
                                                <div className='coverImg_style m-auto mb-3'>
                                                    <div className='uploadFile text-center'>
                                                        <AiOutlineCloudUpload className='uploadIcon' />
                                                        {
                                                            formData.cover_image ? <div>
                                                                <span className='poppins16 fw-bold'>Uploaded :</span> <p>{uploaded_image}</p>
                                                                <img />
                                                            </div>
                                                                :
                                                                <div className='text-center'>
                                                                    <p className='poppins16'>Drag and Drop files or <Link>Browse</Link></p>
                                                                    <p className='poppins12'>Supported format : jpeg, pdf</p>
                                                                </div>
                                                        }
                                                    </div>
                                                    <input type="file" className='selectFileStyle w-100 h-100' onChange={handleFileSelection} />
                                                </div>
                                                <button type='button' className="btn uploadbtn" onClick={handleFileUpload}>Upload Files</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='adminfooterDiv'>
                            <button type='button' className='btn backbtn' onClick={() => { setIsActive({ ...isActive, form: "description" }) }}>Back</button>
                            <button type='submit' className='btn basicnextbtn'>Next</button>
                        </div>
                    </div> */}
                    {/* Upload Photo Form */}
                    <div id="eventPhotos" className={`${isActive.form === "photos" ? "d-block" : "d-none"}`}>
                        <div className='addeventdetailDiv'>
                            <div className='container'>
                                <div className='container'>
                                    <div className='mt-3'>
                                        <div className="text-center mb-3">
                                            <label className="poppins20 fw-semibold ms-1 mb-2 mt-3 text-center" htmlFor='cover_image'>
                                                Cover Image
                                            </label>
                                            <div className='coverImg_style m-auto mb-3'>
                                                <div className='uploadFile text-center'>
                                                    <AiOutlineCloudUpload className='uploadIcon' />
                                                    {formData.cover_image ? (
                                                        <div>
                                                            <span className='poppins16 fw-bold'>Uploaded:</span>
                                                            <p>{uploaded_image}</p>
                                                            <img src={formData.cover_image} alt="Cover" />
                                                        </div>
                                                    ) : (
                                                        <div className='text-center'>
                                                            <p className='poppins16'>Drag and Drop files or <Link>Browse</Link></p>
                                                            <p className='poppins12'>Supported format: jpeg, pdf</p>
                                                        </div>
                                                    )}
                                                </div>
                                                <input type="file" className='selectFileStyle w-100 h-100' onChange={handleFileSelection} />
                                            </div>
                                            <button
                                                type='button'
                                                className="btn uploadbtn"
                                                onClick={handleFileUpload}
                                                disabled={imgloading || !formData.cover_image}  // Disable if no file selected
                                            >
                                                {imgloading ? <SyncLoader animation="border" color="#FFFF" size="7" speedMultiplier={1} margin={4} /> : "Upload Files"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='adminfooterDiv'>
                            <button
                                type='button'
                                className='btn backbtn'
                                onClick={() => { setIsActive({ ...isActive, form: "description" }) }}
                            >
                                Back
                            </button>
                            <button type='submit' className='btn basicnextbtn' disabled={loading}>
                            {loading ? <SyncLoader animation="border" color="#FFFF" size="7" speedMultiplier={1} margin={4} /> : "Next"}</button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    )
}

export default AddEventDetails