import React, { useEffect, useState } from "react";
import VendorSideBar from "../Components/VendorSideBar";
import { HiMiniArrowTrendingUp } from "react-icons/hi2";
import { API_URL } from "../../config";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import NavigationBar from "../Components/NavigationBar";
import PuffLoader from "react-spinners/PuffLoader";

const DashBoard = () => {
    const vendor = JSON.parse(localStorage.getItem("VendorData"));
    const [dashBoard_Values, SetDashBoard_Values] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [events, setEvents] = useState([]);

    const navigate = useNavigate();

    const getDashBoardData = async () => {
        try {
            const response = await axios.get(
                `${API_URL}/vendor/dashboard/${vendor._id || vendor.uid || vendor.vendorBasicDetail._id}`
            );
            if (response.status === 200) {
                SetDashBoard_Values(response.data);
            }
        } catch (error) {
            console.error("Error fetching dashboard data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const getTotalSoldTickets = async (eventId) => {
        try {
            const result = await axios.get(`${API_URL}/vendor/solddatadashboard/${eventId}`);
            if (result.status === 200) {
                return result.data
                    .filter((item) => item.isPaid)
                    .reduce((total, item) => total + item.bookedTicketQuantities, 0);
            }
        } catch (error) {
            console.error("Error fetching ticket sold data:", error);
        }
        return 0;
    };

    const getEventList = async () => {
        try {
            const result = await axios.get(`${API_URL}/vendor/events/${vendor._id || vendor.uid}`);
            const sortedData = result.data.sort((a, b) => new Date(b.event_starts_date) - new Date(a.event_starts_date));

            const eventsWithSoldData = await Promise.all(
                sortedData.map(async (event) => ({
                    ...event,
                    ticketsSold: await getTotalSoldTickets(event._id),
                }))
            );

            setEvents(eventsWithSoldData);
        } catch (error) {
            alert("Something went wrong!");
        }
    };

    const totalticket = (ticket) => {
        return ticket.reduce((total, t) => total + t.total_Quantity, 0).toLocaleString();
    };

    const handleShowEventDetails = (event_id) => {
        navigate(`/vendor/event-detail/${event_id}`);
    };

    useEffect(() => {
        getDashBoardData();
        getEventList();
    }, []);

    return (
        <>
            <NavigationBar />
            {isLoading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
                    <PuffLoader size={48} loading={isLoading} />
                </div>
            ) : (
                <div className="container-fluid">
                    <div className="row">
                        <VendorSideBar />
                        <div className="col-lg-10 col-md-10 col-12 px-2">
                            <div>
                                <div className="d-flex justify-content-between border-bottom border-primary">
                                    <span className="vender-heading">Your Dashboard</span>
                                </div>
                                <div className="row mb-5 mt-2">
                                            <div className='col-lg-3 col-md-3 mb-2'>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <p className='dashboard-summary fw-semibold'>Total Events Hosted</p>
                                                                <p className="count-style fw-semibold">{dashBoard_Values.event_hosted ? dashBoard_Values.event_hosted : "0"}</p>
                                                            </div>
                                                            <div>

                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span className='dashboard-summary fw-semibold'><span className="text-success"><HiMiniArrowTrendingUp /> 8.5% </span>
                                                                <span>Up from last year</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-md-3 mb-2'>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <p className='dashboard-summary fw-semibold'>Total Tickets Sold</p>
                                                                <p className="count-style fw-semibold">{dashBoard_Values.ticket_sold ? dashBoard_Values.ticket_sold : "0"}</p>
                                                            </div>
                                                            <div></div>
                                                        </div>
                                                        <div>
                                                            <span className='dashboard-summary fw-semibold'><span className="text-success"><HiMiniArrowTrendingUp /> 1.3% </span>
                                                                <span>Up from past week</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-md-3 mb-2'>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <p className='dashboard-summary fw-semibold'>Total Tickets Hosted</p>
                                                                <p className="count-style fw-semibold">{dashBoard_Values.ticket_hosted ? dashBoard_Values.ticket_hosted : "0"}</p>
                                                            </div>
                                                            <div></div>
                                                        </div>
                                                        <div>
                                                            <span className='dashboard-summary fw-semibold'><span className="text-success"><HiMiniArrowTrendingUp /> 1.8% </span>
                                                                <span>Up from year</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-md-3 mb-2'>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <p className='dashboard-summary fw-semibold'>Total Revenue</p>
                                                                <p className="count-style fw-semibold">{dashBoard_Values.ticket_revenue ? parseInt(dashBoard_Values.ticket_revenue).toFixed(2) : "0"}</p>
                                                            </div>
                                                            <div></div>
                                                        </div>
                                                        <div>
                                                            <span className='dashboard-summary fw-semibold'><span className="text-success"><HiMiniArrowTrendingUp /> 4.3% </span>
                                                                <span>Down from yesterday</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
<                                div className="card">
                                    <div className="card-body">
                                        <p className="vender-heading">Events Details </p>
                                        <table className="table table-responsive table-hover">
                                            <thead className="tableHead">
                                                <tr>
                                                    <th scope="col">Event Name</th>
                                                    <th className="hideit_768" scope="col">Location</th>
                                                    <th className="hideit_768" scope="col">Date</th>
                                                    <th className="hideit_768" scope="col">Tickets Hosted</th>
                                                    <th className="hideit_768" scope="col">Tickets Sold</th>
                                                    <th scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {events.length > 0 ? (
                                                    events.slice(0, 3).map((event) => (
                                                        <tr
                                                            className="tableDataRow"
                                                            key={event._id}
                                                            onClick={() => handleShowEventDetails(event.unique_id)}
                                                        >
                                                            <td>{event.event_name}</td>
                                                            <td className="hideit_768">{event.event_city}</td>
                                                            <td className="hideit_768">
                                                                {new Date(event.event_starts_date)
                                                                    .toLocaleDateString("en-GB")
                                                                    .replace(/\//g, "-")}
                                                            </td>
                                                            <td className="hideit_768">{totalticket(event.ticket)}</td>
                                                            <td className="hideit_768">{event.ticketsSold}</td>
                                                            <td>
                                                                <span className="vendor-event-status-active">
                                                                    {new Date(event.event_ends_date) < new Date()
                                                                        ? "Completed"
                                                                        : "Active"}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <p>.........No Event Added........</p>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DashBoard;
